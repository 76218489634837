@keyframes galleryItemAppearFromLeft {
  0% {
    left: -105%;
  }
  50% {
    z-index: 8;
    opacity: 1;
  }
  100% {
    left: 0;
    z-index: 8;
    opacity: 1;
  }
}

@keyframes galleryItemAppearFromRight {
  0% {
    left: 105%;
  }
  50% {
    z-index: 8;
    opacity: 1;
  }
  100% {
    left: 0;
    z-index: 8;
    opacity: 1;
  }
}
