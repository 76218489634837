@use "../mixins";

:root {
  // Default variables
  --gallery-content-width: 100%;
  --gallery-content-height: 250px;
  --gallery-content-bg-color: var(--color-bg);

  // Item variables
  --gallery-item-bg-size: cover;

  // Title variables
  --gallery-title-font-size: 2rem;
  --gallery-title-font-color: white;
  --gallery-title-align: left;

  // Description variables
  --gallery-description-font-size: 1.1rem;
  --gallery-description-font-color: white;
  --gallery-description-align: left;

  // Btn variables
  --gallery-btn-size: 10px;
  --gallery-btn-rounded: calc(var(--gallery-btn-size) / 2);
  --gallery-bg-color: rgba(0, 0, 0, .3);
  --gallery-border-color: rgba(0, 0, 0, .4);

  @include mixins.tabletView {
    // Default variables
    --gallery-content-height: 450px;

    // Title variables
    --gallery-title-font-size: 3rem;

    // Btn variables
    --gallery-btn-size: 12px;
  }
}

[data-theme="dark"] {
  // Btn variables
  --gallery-border-color: rgba(255, 255, 255, .4);
}
