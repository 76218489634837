:root {
  // Override ckeditor variable
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;
  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;
  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: var(--color-bg);
  --ck-custom-foreground: var(--color-bg-hover);
  --ck-custom-border: var(--color-border);
  --ck-custom-white: white;
  --ck-color-button-on-color: var(--color-txt);
  --ck-color-base-active: var(--color-primary);
  --ck-color-base-active-focus: var(--color-primary);
  --ck-color-base-background: var(--color-bg);
  --ck-color-base-border: var(--color-border);
  --ck-color-base-action: var(--color-primary);
  --ck-color-base-focus: var(--color-primary-hover);
  --ck-color-base-text: var(--color-txt);
  /* -- Overrides generic colors. ------------------------------------------------------------- */
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: var(--color-border);
  --ck-color-on-border: var(--color-border);
  --ck-color-focus-outer-shadow: var(--color-primary-light);
  --ck-color-on-outer-shadow: var(--color-primary-light);
  --ck-color-text: var(--color-txt);
  --ck-color-shadow-drop: rgba(0, 0, 0, .15);
  --ck-color-shadow-inner: transparent;
  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */
  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: var(--color-bg-hover);
  --ck-color-button-default-active-background: var(--color-bg-hover);
  --ck-color-button-default-active-shadow: var(--ck-color-shadow-drop);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: var(--color-bg-hover);
  --ck-color-button-on-active-background: var(--color-bg-hover);
  --ck-color-button-on-active-shadow: var(--ck-color-shadow-drop);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-switch-button-on-hover-background: var(--color-primary-hover);
  --ck-color-button-action-background: var(--color-primary);
  --ck-color-button-action-hover-background: var(--color-primary-hover);
  --ck-color-button-action-active-background: var(--color-primary);
  --ck-color-button-action-active-shadow: var(--ck-color-shadow-drop);
  --ck-color-button-action-disabled-background: var(--color-bg);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: var(--color-primary);
  --ck-color-button-cancel: var(--color-danger);
  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */
  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-border);
  /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */
  --ck-color-dialog-background: var(--ck-custom-background);
  --ck-color-dialog-form-header-border: var(--ck-custom-border);
  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */
  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-border);
  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */
  --ck-color-input-background: var(--color-bg);
  --ck-color-input-border: var(--ck-custom-border);
  --ck-color-input-text: var(--color-txt);
  --ck-color-input-disabled-background: var(--color-bg-hover);
  --ck-color-input-disabled-border: var(--ck-custom-border);
  --ck-color-input-disabled-text: var(--color-txt);
  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */
  --ck-color-labeled-field-label-background: var(--ck-custom-background);
  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */
  --ck-color-list-background: var(--color-bg);
  --ck-color-list-button-hover-background: var(--color-bg-hover);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: white;
  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */
  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);
  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);
  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */
  --ck-color-tooltip-background: var(--color-bg);
  --ck-color-tooltip-text: var(--color-txt);
  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */
  --ck-color-image-caption-background: var(--color-bg);
  --ck-color-image-caption-text: var(--color-txt);
  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */
  --ck-color-widget-blurred-border: var(--ck-custom-border);
  --ck-color-widget-hover-border: var(--ck-custom-border);
  --ck-color-widget-editable-focus-background: var(--ck-custom-background);
  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */
  --ck-color-link-default: var(--color-primary);
  /* -- Overrides the default colors used by styles. ---------------------- */
  --ck-style-panel-button-width: 120px;
  --ck-style-panel-button-height: 80px;
  --ck-style-panel-button-label-background: var(--color-bg-hover);
  --ck-style-panel-button-hover-label-background: var(--color-bg-hover);
  --ck-style-panel-button-hover-border-color: var(--ck-custom-border);
  /* -- Overrides the default colors used by dialog. ---------------------- */
  --ck-dialog-overlay-background-color: transparent;
  --ck-dialog-drop-shadow: 0px 0px 6px 2px rgba(0, 0, 0, .15);
  --ck-dialog-max-width: 100vw;
  --ck-dialog-max-height: 90vh;
  /* -- Overrides the default colors used by mentions. ---------------------- */
  --ck-color-mention-background: var(--color-primary-light);
  --ck-color-mention-text: var(--color-primary);
  // Override ckeditor variable
}

[data-theme="dark"] {

}
