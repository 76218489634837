@use 'mixins';

@import 'cropperjs/src/index.css';


// Refactored at 2024-12-01

* {

  // New_Includes
  @include mixins.fontNunito;
  // New_Includes

  & {
    color: var(--color-txt);
    font-size: var(--font-size);
    box-sizing: border-box;
    position: relative;
  }
}

fa-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

.ck-content {
  blockquote {
    margin: 1em 40px;
    padding-left: 10px;

    p {
      margin: 1rem 0;
    }
  }

  dl {
    margin: 1em 0;

    dd {
      margin-left: 20px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 1em 0 0.5em;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.75em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.25em;
  }

  h5 {
    font-size: 1.1em;
  }

  h6 {
    font-size: 1em;
  }

  hr {
    margin: 1em 0;
  }

  figure {
    margin: 1em 40px;
  }

  p {
    margin: 0 0 1em;
  }

  pre {
    padding: 10px;
  }

  ol, ul, menu {
    margin: 1em 0;
    padding-left: 40px;
  }

  ol {
    list-style-type: decimal;
  }

  ul, menu {
    list-style-type: disc;
  }
}

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon,
.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_left.ck-balloon-panel_visible.ck-evaluation-badge-balloon
{
  display: none !important;
}

.field-loading-items {
  // New_Includes
  @include mixins.threePoint {
    padding: 0;
  }
  // New_Includes

  & {
    user-select: none;
    cursor: wait;
    color: var(--color-txt);
  }

}

.cdk-global-overlay-wrapper {

}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  width: max-content !important;
  max-width: 90% !important;
}
