@keyframes openFromBottomSelectOption {
  0% {
    opacity: 0;
    top: var(--field-select-options-open-bottom-start);
  }
  100% {
    opacity: 1;
    top: var(--field-select-options-open-bottom-end);
  }
}

@keyframes closeFromBottomSelectOption {
  0% {
    opacity: 1;
    top: var(--field-select-options-open-bottom-end);
  }
  100% {
    opacity: 0;
    top: var(--field-select-options-open-bottom-start);
  }
}

@keyframes openFromTopSelectOption {
  0% {
    opacity: 0;
    bottom: var(--field-select-options-open-top-start);
  }
  100% {
    opacity: 1;
    bottom: var(--field-select-options-open-top-end);
  }
}

@keyframes closeFromTopSelectOption {
  0% {
    opacity: 1;
    bottom: var(--field-select-options-open-top-end);
  }
  100% {
    opacity: 0;
    bottom: var(--field-select-options-open-top-start);
  }
}
