@keyframes rotateAnimate {
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
