:root {
  --dashboard-font-family: 'M PLUS Rounded 1c';

  --dashboard-h1-font-size: 36px;
  --dashboard-h2-font-size: 32px;
  --dashboard-h3-font-size: 28px;
  --dashboard-h4-font-size: 24px;
  --dashboard-h5-font-size: 20px;
  --dashboard-h6-font-size: 16px;
  --dashboard-navbar-icon-font-size: 16px;

  --dashboard-padding-x: 25px;
  --dashboard-padding-y: 18px;

  --dashboard-dark-color: #384a73;
  --dashboard-light-color: #f7f8ff;
  --dashboard-no-active-color: #b3b7c1;
  --dashboard-nav-children-bg-color: rgba(64, 68, 99, .03);

  --dashboard-bg-color: var(--dashboard-light-color);
  --dashboard-font-color: var(--dashboard-dark-color);
  --dashboard-active-color: var(--color-primary);

  --dashboard-sidebar-bg-color: var(--dashboard-light-color);

  --dashboard-content-bg-color: var(--dashboard-light-color);

  --dashboard-navbar-bg-color: var(--dashboard-light-color);

  --dashboard-select-bg: var(--dashboard-dark-color);
  --dashboard-select-txt: var(--dashboard-light-color);

  --dashboard-nav-active-border-color: var(--dashboard-dark-color);

  --dashboard-card-width: 100%;
  --dashboard-card-rounded: 7px;
  --dashboard-card-padding: 25px;
  --dashboard-card-bgcolor: #ffffff;
  --dashboard-card-shadow-color: rgba(0, 0, 0, .05);
  --dashboard-card-shadow: 0 5px 12px 6px var(--dashboard-card-shadow-color);
}

[data-theme="dark"] {
  --dashboard-dark-color: #bdbdca;
  --dashboard-light-color: #212d43;
  --dashboard-no-active-color: #636b7c;
  --dashboard-nav-children-bg-color: rgba(0, 0, 0, .08);

  --dashboard-card-bgcolor: #273450;
  --dashboard-card-shadow-color: rgba(0, 0, 0, .18);
}

